<template>
  <Page title="Dokumente" color="error">
    <template slot="extension">
      <v-tabs background-color="error darken-1">
        <v-toolbar color="transparent" flat dense>
          <v-spacer />
          <v-text-field
            single-line
            dense
            hide-details
            label="Suche"
            v-model="search"
            append-icon="mdi-magnify"
            clearable
          ></v-text-field>
        </v-toolbar>
      </v-tabs>
    </template>
    <router-view v-model="search"></router-view>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      search: '',
    };
  },
  watch: {
    async search() {
      if (!this.search) {
        this.$router.push({
          name: 'DocumentCategories',
        });
      } else {
        this.$router.push({
          name: 'DocumentsSearch',
          params: { search: this.search },
        });
      }
    },
  },
};
</script>
